import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/turkey-LP_header.jpg";
import CertAccordFire from "../images/certificates/accord-on-fire.png";
import CertAlliance from "../images/certificates/alliance-bangladesh-worker-safety.png";
import CertBSCI from "../images/certificates/BSCI.jpg";
import CertBluesign from "../images/certificates/bluesign.png";
import CertFairwear from "../images/certificates/fairwear.png";
import CertGoreTex from "../images/certificates/gore-tex.png";
import CertGOTS from "../images/certificates/gots.png"
import CertGRS from "../images/certificates/GRS.png";
import CertICS from "../images/certificates/ics-initiative-compliance-sustainability.png";
import CertISO14001 from "../images/certificates/iso-14001-environmental-management-system.png";
import CertOHSAS from "../images/certificates/ohsas-18001-occupational-safety-and-health.png";
import CertISO50001 from "../images/certificates/iso-50001-energy-management-system.png";
import CertOCS1 from "../images/certificates/OCS.png";
import CertOCS2 from "../images/certificates/OCS-2.png";
import CertOEKODetox from "../images/certificates/oeko_tex_detox_zero.png";
import CertOEKOLeather from "../images/certificates/oeko_tex_leather.png";
import CertOEKOGreen from "../images/certificates/oeko_tex_made_in_green.png";
import CertOEKO100 from "../images/certificates/oeko_tex_std_100.png";
import CertOEKODetoxStep from "../images/certificates/oeko_tex_detox_step.png";
import CertQualitySystem from "../images/certificates/quality-management-system.png";
import CertRCS from "../images/certificates/RCS.png";
import CertRCSte from "../images/certificates/RCS_TE.png";
import CertRDS from "../images/certificates/RDS.png";
import CertRWS from "../images/certificates/RWS.png";
import CertSa8000 from "../images/certificates/sa8000-standard.png";
import CertSedex from "../images/certificates/sedex.png";
import CertWrap from "../images/certificates/wrap.png";
import CertBCI from '../images/certificates/better-cotton-logo.png';
import CertRMS from '../images/certificates/responsible-mohair-standard.png';

// icons
import HandshakeIcon from "../images/icon/handshake-icon.svg";
import ShowroomIcon from "../images/icon/showroom-icon.svg";
import FabricsIcon from "../images/icon/fabrics-icon.svg";
import RFQsIcon from "../images/icon/RFQs-icon.svg";
import CertsIcon from "../images/icon/certificates-icon.svg";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("turkey.turkeyTopTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("turkey.turkeyTopSubTitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      full: false,
      image: {
        src: TopBackground,
        alt: t("turkey.imgAlt"),
        bigHeight: true,
      },
    },
    ctaJoin: {
      text: t("turkey.joinFree"),
      url: process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    suppliersTurkey: {
      title: {
        text: t("turkey.suppliersTurkey"),
        colorClass: "colorDark",
        extraClass: "font26",
      },
      separator: true,
    },
    foursourceEfficiency: {
      title: {
        text: t("turkey.foursourceEfficiency"),
        colorClass: "colorDark",
        extraClass: "font26",
      },
      separator: true,
    },
    process: [
      { icon: { src: HandshakeIcon, alt: t("turkey.handshakeIconAlt") }, text: t("turkey.connectSuppliers") },
      { icon: { src: ShowroomIcon, alt: t("turkey.showroomIconAlt") }, text: t("turkey.discoverProducts") },
      { icon: { src: FabricsIcon, alt: t("turkey.fabricsIconAlt") }, text: t("turkey.findFabrics") },
      { icon: { src: RFQsIcon, alt: t("turkey.rfqIconAlt") }, text: t("turkey.compareQuotes") },
      { icon: { src: CertsIcon, alt: t("turkey.certsIconAlt") }, text: t("turkey.verifiedInfo") },
    ],
    certificates: {
      "accord-on-fire": { src: [CertAccordFire], alt: t("turkey.accord-on-fire")},
      "alliance-bangladesh-worker-safety": {
        src: [CertAlliance],
        alt: t("turkey.alliance-bangladesh-worker-safety"),
      },
      "amfori-bsci": { src: [CertBSCI], alt: t("turkey.amfori-bsci") },
      bluesign: { src: [CertBluesign], alt: t("turkey.bluesign") },
      bci: {src: [CertBCI], alt: t("turkey.bci")},
      fairwear: { src: [CertFairwear], alt: t("turkey.fairwear") },
      "gore-tex": { src: [CertGoreTex], alt: t("turkey.gore-tex") },
      gots: { src: [CertGOTS], alt: t("turkey.gots") },
      "grs-global-recycled-standard": { src: [CertGRS], alt: t("turkey.grs-global-recycled-standard") },
      "ics-initiative-compliance-sustainability": {
        src: [CertICS],
        alt: t("turkey.ics-initiative-compliance-sustainability"),
      },
      "iso-14001-environmental-management-system": {
        src: [CertISO14001],
        alt: t("turkey.iso-14001-environmental-management-system"),
      },
      "iso-45001-occupational-safety-and-health": {
        src: [CertOHSAS],
        alt: t("turkey.iso-45001-occupational-safety-and-health"),
      },
      "iso-50001-energy-management-system": {
        src: [CertISO50001],
        alt: t("turkey.iso-50001-energy-management-system"),
      },
      "ocs-organic-content-standard": {
        src: [CertOCS1, CertOCS2],
        alt: t("turkey.ocs-organic-content-standard"),
      },
      "oeko-tex-detox-zero": { src: [CertOEKODetox], alt: t("turkey.oeko-tex-detox-zero") },
      "oeko-tex-leather-standard": {
        src: [CertOEKOLeather],
        alt: t("turkey.oeko-tex-leather-standard"),
      },
      "oeko-tex-made-in-green": {
        src: [CertOEKOGreen],
        alt: t("turkey.oeko-tex-made-in-green"),
      },
      "oeko-tex-standard-100": { src: [CertOEKO100], alt: t("turkey.oeko-tex-standard-100") },
      "oeko-tex-step": { src: [CertOEKODetoxStep], alt: t("turkey.oeko-tex-step") },
      "quality-management-system": { src: [CertQualitySystem], alt: t("turkey.quality-management-system") },
      "rcs-recycled-claim-standard": {
        src: [CertRCS, CertRCSte],
        alt: t("turkey.rcs-recycled-claim-standard"),
      },
      "rds-responsible-down-standard": { src: [CertRDS], alt: t("turkey.rds-responsible-down-standard") },
      "rms-responsible-mohair-standard": {
        src: [CertRMS], alt: t("turkey.rms-responsible-mohair-standard") 
      },
      "rws-responsible-wool-standard": { src: [CertRWS], alt: t("turkey.rws-responsible-wool-standard") },
      "sa8000-standard": { src: [CertSa8000], alt: t("turkey.sa8000-standard") },
      sedex: { src: [CertSedex], alt: t("turkey.sedex") },
      wrap: { src: [CertWrap], alt: t("turkey.wrap") },
    },
  };
};
