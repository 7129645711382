import * as React from "react";
import { withTrans } from "../i18n/withTrans";
import classNames from "classnames";

import { pageTexts } from "../content/turkey";

import "../style/components/switcher.scss";

// images
import LogosImg from "../images/sliderlogos_eib.jpg";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Button from "../components/_partials/_button";
import TitleSeparator from "../components/titleSeparator";
import MemberBox from "../components/memberBox";
import LogoSlide from "../components/logoSlide";

const SMALL_WINDOW = 1920;
const api = process.env.API;
const username = process.env.USERNAME;
const passwordFabric = process.env.PASSWORD_FABRIC;
const passwordManufacturer = process.env.PASSWORD_MANUFACTURER;
class TurkeyPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.state = {
      isFabricActive: true,
      turkeyManufacturers: [],
      turkeyFabrics: [],
      fabricPage: 1,
      manufacturerPage: 1,
      smallWindow:
        typeof window !== "undefined"
          ? window.innerWidth < SMALL_WINDOW
            ? true
            : false
          : "",
      seeMoreFabrics: true,
      seeMoreManufacturers: true,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize, true);
    this.fetchTurkeyFabrics();
  }

  handleResize = () => {
    let show = window.innerWidth < SMALL_WINDOW ? true : false;
    this.setState({
      smallWindow: show,
    });
  };

  seeMore = (type) => {
    if (type === "fabric") {
      this.setState({ fabricPage: this.state.fabricPage + 1 });
      this.fetchTurkeyFabrics(this.state.fabricPage);
    } else {
      this.setState({ manufacturerPage: this.state.manufacturerPage + 1 });
      this.fetchTurkeyManufacturers(this.state.manufacturerPage);
    }
  };

  render() {
    return (
      <>
        <Layout pageName="turkey" whiteMenu={false}>
          <TextImgBackgroundFull
            info={this.text.topInfo}
          ></TextImgBackgroundFull>

          <div
            className={
              (this.state.smallWindow ? "paddingSides" : "paddingSidesBigger") +
              " turkey pt-30 pb-50 text-center"
            }
          >
            <TitleSeparator info={this.text.suppliersTurkey}></TitleSeparator>
            <div className="switcher d-flex justify-content-center c-pointer">
              <div
                className={classNames("switch", "h-100", {
                  active: this.state.isFabricActive,
                })}
                onClick={() => this.setState({ isFabricActive: true })}
                onKeyDown={() => this.setState()}
                tabIndex={0}
                role="button"
              >
                {this.t("turkey.fabric")}
              </div>
              <div
                className={classNames("switch", "h-100", {
                  active: !this.state.isFabricActive,
                })}
                onClick={() => {
                  this.fetchTurkeyManufacturers();
                  this.setState({ isFabricActive: false });
                }}
                onKeyDown={() => this.setState()}
                tabIndex={0}
                role="button"
              >
                {this.t("turkey.apparel")}
              </div>
            </div>
            {this.state.isFabricActive ? (
              <div id="fabric-members">
                <div className="pt-10 row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-col-sm-2 row-cols-1 m-0">
                  {this.state.turkeyFabrics.map((member, i) => (
                    <div key={i} className="col mb-25">
                      <MemberBox
                        info={member}
                        certs={this.text.certificates}
                        type="fabric"
                      ></MemberBox>
                    </div>
                  ))}
                </div>
                {this.state.seeMoreFabrics && (
                  <div className="pt-50">
                    <button
                      className="whiteColor greenBorder ctaButton bigWidth"
                      onClick={() => {
                        this.seeMore("fabric");
                      }}
                    >
                      {this.t("turkey.seeMore")}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div id="apparel-members">
                <div className="pt-10 row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-col-sm-2 row-cols-1 m-0">
                  {this.state.turkeyManufacturers.map((member, i) => (
                    <div key={i} className="col mb-25">
                      <MemberBox
                        info={member}
                        certs={this.text.certificates}
                        type="manufacturer"
                      ></MemberBox>
                    </div>
                  ))}
                </div>
                {this.state.seeMoreManufacturers && (
                  <div className="pt-50">
                    <button
                      className="whiteColor greenBorder ctaButton bigWidth"
                      onClick={() => {
                        this.seeMore("manufacturers");
                      }}
                    >
                      {this.t("turkey.seeMore")}
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="pt-50">
              <Button cta={this.text.ctaJoin}></Button>
            </div>
          </div>

          <div className="topBottomDiagonal backgroundGrey paddingSidesBig text-center pt-100 pb-100">
            <TitleSeparator
              info={this.text.foursourceEfficiency}
            ></TitleSeparator>
            <div className="row m-0 row-cols-5 pt-30">
              {this.text.process.map((info, i) => (
                <div key={i} className="col pr-15 pl-15">
                  <img
                    className="height-50px"
                    src={info.icon.src}
                    alt={info.icon.alt}
                  />
                  <p className="pt-20 font17 fontWeight700 lineHeight1_7">
                    {info.text}
                  </p>
                </div>
              ))}
            </div>
            <div className="pt-30">
              <Button cta={this.text.ctaJoin}></Button>
            </div>
          </div>
          <div className="pt-30 pb-50">
            <LogoSlide img={LogosImg} duration="18"></LogoSlide>
          </div>
        </Layout>
      </>
    );
  }

  fetchTurkeyManufacturers = (pageNumber) => {
    let headers = new Headers();
    headers.set("Domain", "public");
    fetch(
      pageNumber
        ? api + `search/manufacturers?page=` + pageNumber + `&limit=32`
        : api + `search/manufacturers?limit=32`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Basic " + btoa(username + ":" + passwordManufacturer),
        },
      }
    )
      .then((response) => response.json())
      .then((resultData) => {
        let currentArray = [...this.state.turkeyManufacturers];
        resultData?.data?.results?.forEach((result) => {
          currentArray.push(result);
        });
        if (resultData?.data?.results?.length < 32) {
          this.setState({ seeMoreManufacturers: false });
        }
        this.setState({ turkeyManufacturers: currentArray });
      });
  };
  fetchTurkeyFabrics = (pageNumber) => {
    let headers = new Headers();
    headers.set("Domain", "public");
    fetch(
      pageNumber
        ? api + `search/fabrics?page=` + pageNumber + `&limit=32`
        : api + `search/fabrics?limit=32`,
      {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          Authorization: "Basic " + btoa(username + ":" + passwordFabric),
        },
      }
    )
      .then((response) => response.json())
      .then((resultData) => {
        let currentArray = [...this.state.turkeyManufacturers];
        resultData.data.results.forEach((result) => {
          currentArray.push(result);
        });
        if (resultData.data.results.length < 32) {
          this.setState({ seeMoreFabrics: false });
        }
        this.setState({ turkeyFabrics: currentArray });
      });
  };
}

export default withTrans(TurkeyPage);
