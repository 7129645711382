import * as React from "react";
import "../style/components/memberBox.scss";

import { withTrans } from "../i18n/withTrans";

import { StaticImage } from "gatsby-plugin-image";

class memberBox extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    const { info, certs, type} = this.props;
    const domain = process.env.IMAGES_DOMAIN;
    const member = {
      logo: `${domain}/${type}/miniprofile-logo/sm/${info?.logo}`,
      name: info?.name,
      city: info?.information?.headquarter?.city,
      country: info?.information?.headquarter?.country,
      productGroup: info?.information?.product_group,
      fabricTypes: info?.information?.fabric_types,
      moq: info?.information?.moq ,
      yearlyProduction: info?.information?.yearly_production,
      targetGroup: info?.information?.target_group,
      productSector: info?.information?.product_sectors,
      certificates: info?.information?.certificates,
      images: (info?.information.showroom ? info?.information?.showroom : info?.information?.catalogue),
      url:  `${process.env.GATSBY_CHINA === "true" ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup/`
    };
    return (
      <>
        <a href={member.url} target="_blank" rel="noreferrer">
          <div className="memberBoxContent backgroundWhite boxShadow mt-30 p-10 h-100">
            <div className="d-flex w-100">
              {info.logo ? (
                <img className="logo d-flex" src={member.logo} alt={member.name} />
              ) : (
                <StaticImage
                  src="../images/manufacturer-logo.png"
                  aspectRatio={1 / 1}
                  className="logo d-flex width-25"
                  alt={this.t("turkey.foursourceDefaultIcon")}
                  placeholder="blurred"
                />
              )}
              <div className="flex-fill pl-2 w-75">
                <p className="name text-left font11 fontWeight600 lineHeight1 m-0 p-0">{member.name ?? "-"}</p>
                <p className="city text-left font11 m-0 p-0">{member.city ?? "-"}</p>
              </div>
            </div>
            {member.images ? (
              <div className="images row row-cols-3 pt-10 pb-10">
                {member.images.map((image, i) => (
                  <div key={i} className="col">
                    <img className="image max-width-55px" src={type==="manufacturer"? `${domain}/${type}/post-feed/sm/${image.location}`: `${domain}/${type}/catalogue-post/sm/${image.location}`} alt={image.id_feed_image} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="content row m-0 text font11 colorDark fontWeight700 m-0 lineHeight1_3 pt-15 pb-15">{this.t("turkey.noImages")}</div>
            )}
            <div className="content row m-0">
              <div className="w-100 text-left font11">
                <p className="title colorDarkLight m-0">{type ==="manufacturer"  ? this.t("turkey.productGroup") : this.t("turkey.fabricTypes")}</p>
                { type === 'manufacturer' ?
                  <p className="text colorDark fontWeight700 m-0 lineHeight1_3">{member.productGroup.join(", ")||"-"}</p>:
                  <p className="text colorDark fontWeight700 m-0 lineHeight1_3">{member.fabricTypes.join(", ") ||"-"}</p>
                }
              </div>
              <div className="w-100 text-left font11 mt-10">
                <p className="title colorDarkLight m-0">{type ==="manufacturer"  ? this.t("turkey.moq") : this.t("turkey.yearly")}</p>
                { type === 'manufacturer' ?
                  <p className="text colorDark fontWeight700 m-0 lineHeight1_3">{member.moq || "-"}</p>:
                  <p className="text colorDark fontWeight700 m-0 lineHeight1_3">{member.yearlyProduction || "-"}</p>
                }
              </div>
              <div className="w-100 text-left font11 mt-10">
                <p className="title colorDarkLight m-0">{type ==="manufacturer"  ? this.t("turkey.targetGroup") : this.t("turkey.productSector")}</p>
                { type === 'manufacturer' ?
                  <p className="text colorDark fontWeight700 m-0 lineHeight1_3">{member.targetGroup.join(", ") || "-"}</p>:
                  <p className="text colorDark fontWeight700 m-0 lineHeight1_3">{member.productSector.join(", ") || "-"}</p>
                }
              </div>
              <div className="w-100 text-left font11 mt-10">
                <p className="title colorDarkLight m-0 pb-1">{this.t("turkey.certificates")}</p>
                {member.certificates && member.certificates.length > 0
                  ? member.certificates.map((cert) =>
                      certs[cert.key]?.src?.map((image, j) => <img key={j} className="cert mr-10 mt-1" src={image} alt={certs[cert.key]?.alt} />)
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </a>
      </>
    );
  }
}

export default withTrans(memberBox);
